@import url("https://fonts.googleapis.com/css?family=Press+Start+2P");

.home {
    /* background-image: url(https://gifdb.com/images/thumbnail/anime-glittery-blue-sky-bbxetmsyq2tpr5cq.gif); */
    /* background-image: url(https://38.media.tumblr.com/c1ade96ad9e319159057140241d8fa5a/tumblr_mw65fcWIjI1t01esgo1_500.gif); */
   /* background-image: url(https://media.giphy.com/media/lKaeQAunM3hZaqsOpj/giphy.gif); */
   /* background-image: url(https://media.giphy.com/media/3ohhwi8j3zjpDMhvxK/giphy.gif); */
    color: black;
    font-family: "Press Start 2P";
    font-size: small;
}

@media screen and (max-width: 768px) {
    .home {
        padding-top: 10vh;
    }
}

@media screen and (min-width: 768px) {
    .home {
      min-height: 100vh; 
      padding-top: 3vh;
      background-image: url(https://media.giphy.com/media/3zvbrvbRe7wxBofOBI/giphy.gif);
      background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    }
    .typewriter-row {
text-shadow: 2px 2px 3px #4AF626;
        color: white;
    }
  }

.typewriter-row {
    margin-bottom: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .enter-btn{
    color: #4AF626 !important;
    margin-left: 25% !important;
}

.enter-btn:hover{
    background-color: #4AF626 !important;
    color:black !important;
} */

.enter-btn{
    color: black !important;
    margin-left: 25% !important;
}

.enter-btn:hover{
    background-color: black !important;
    color:white !important;
}


.button-col{
    justify-content: center;
    align-items: center;
}

.quick-order-row {
    display: flex;
    justify-content: space-evenly;
}