@import url("https://fonts.googleapis.com/css?family=Press+Start+2P");
@import url("https://fonts.googleapis.com/css?family=Audiowide");

/* App.css */

/* General styles */
body {
  font-family: "Press Start 2P";
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  margin: 20px;
}

/* Header styles */

h1 {
  margin: 0;
  font-size: 24px;
}

/* Product Card styles */
.product-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.product-card {
  border: 1px solid black;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-card img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
}

.product-card h3 {
  margin-top: 10px;
  font-size: 18px;
}

.product-card p {
  font-weight: bold;
}

.add-to-cart, .enter-btn2, .home-checkout-btn, .blog-post-btn {
  margin-top: 10px;
  background-color: #4AF626;
  color: black;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
}

.add-to-cart:hover, .enter-btn2:hover, .home-checkout-btn:hover, .blog-post-btn:hover {
  background-color: black;
  color:#4AF626;
  border: 1px solid black;
}

/* .home {
  background-color: black;
  height: 100vh;
} */

.enter-btn {
  margin-left: 50vw;
}

.cart h2 {
  margin: 0;
  font-size: 24px;
}

.cart ul {
  list-style: none;
  padding: 0;
}

.cart li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.home-checkout-btn {
  margin-left: 50%;
}

.cart li button {
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
}

.cart {
  margin-top: 40px;
  border: 1px solid black;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-card select {
  margin-top: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.quantity-control {
  background: none;
  border: none;
  color: #4AF626;
}

.quantity-control:hover {
  cursor: pointer;
}

.quantity-dropdown-select {
  margin: 20px;
}
/* Add the following CSS to your stylesheets or use inline styles */
/* Add the following CSS to your stylesheets or use inline styles */

.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.slider {
  display: flex;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
}

.slider-item {
  width: 300px; /* Adjust the width of each photo container as needed */
  height: 200px; /* Adjust the height of each photo container as needed */
  margin: 0 5px;
  overflow: hidden;
  padding: 20px;
  /* border: solid 1px #4AF626; */
}

.slider-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-arrows {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

/* Style the arrow buttons as needed */
.slider-arrows button {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 5px;
}

/* Disable arrow buttons when not applicable */
.slider-arrows button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .slider-item {
    width: 250px; /* Adjust the width of each photo container for mobile devices */
    height: 150px; /* Adjust the height of each photo container for mobile devices */
  }
}

.order {
  background-color: white;
  color:black;
  font-family: "Press Start 2P";
  min-height: 100vh;
  padding-top: 30px !important;
}

.order-header {
  padding-top: 60px;
  padding-left: 20px;
}


.back-to-home {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: black !important;
  color: #4AF626;
  border: none;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .order {
    padding: 10px;
  }

  .prder-header {
    flex-direction: column;
  }

  .order-header h1 {
    margin-bottom: 10px;
  }

  .product-grid {
    grid-template-columns: 1fr;
  }

  .product-card img {
    max-height: 150px;
  }
}

.app-container {
 padding: 0px !important;
}

.blog {
  min-height: 100vh;
}

.blog, .blogpost {
  font-family: "Audiowide";
  /* font-weight: lighter; */
}

.iframe_container {
  left: 0;
  width: "100%";
  height: 500;
  position: "relative"
}

.iframe {
top: 0;
left: 0;
width: "100%";
height: "100%";
position: "absolute";
border: 0
}