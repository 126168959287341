@import url("https://fonts.googleapis.com/css?family=Press+Start+2P");

.checkout-page {
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    color:black;
    min-width: 100vw;
    /* min-height: 100vh; */
    font-family: "Press Start 2P";
  }

  .checkout-header {
    padding: 60px;
  }
  
  h2 {
    margin: 0 0 20px;
    font-size: 24px;
  }
  
  .checkout-btn {
    background-color: pink;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 12px 16px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    margin-top: 20px;
  }
  
  .checkout-btn:hover {
    background-color: hotpink;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }


.back-to-menu {
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: black;
    border-radius: 8px;
    color: #4AF626;
    border: none;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  
  
  
  