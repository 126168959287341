@import url("https://fonts.googleapis.com/css?family=Press+Start+2P");

@media only screen and (max-width: 991px) {
  .large-header {
    display: none;
  }
}

@media only screen and (min-width: 991px) {
    .small-screen-nav {
      display: none !important;
    }
  }

  @media only screen and (max-width: 991px) {
    .big-screen-nav {
      display: none !important;
    }
  }
.big-screen-nav {
    font-family: 'Press Start 2P';
}

.fixed-top {
  background-color: white;
  border: 1px solid black;
  padding-bottom: 2vh;
  margin-bottom: 2vh;
}

@media only screen and (min-width: 992px) {
  .small-header {
    display: none;
  }
}
.site__header {
  /* top: 50px; */
  padding-left: 10px;
  padding-right: 10px;
}

.menu__button {
  color: black;
}

.menu__button:focus,
.menu__button:hover {
  color: black;
  box-shadow: unset;
}

.menu__button svg {
  width: 2em;
  height: 2em;
  fill: black;
  color: black;
}

.nav_ac {
  padding: 5px 15px;
  margin: 0;
  border: unset;
  background: white;
  font-size: 1.25rem;
  font-family: "Press Start 2P";
  color: black;
  line-height: 2;
  height: 50px;
  font-weight: bold;
  z-index: 1000;
}

.nav_ac:hover {
  color: black;
}

.br-top,
.br-bottom,
.br-right,
.br-left {
  position: fixed;
  z-index: 999999;
  background: black;
}

.br-top {
  top: 0;
  height: 10px;
  left: 0;
  width: 100%;
}

.br-bottom {
  bottom: 0;
  left: 0;
  height: 10px;
  width: 100%;
}

.br-right {
  width: 10px;
  right: 0;
  top: 0;
  height: 100%;
}

.br-left {
  width: 10px;
  left: 0;
  top: 0;
  height: 100%;
}

.cortina__wrapper-menu {
  position: relative;
  width: 100%;
  padding-top: 5em;
  padding-bottom: 3em;
  height: 100%;
  overflow-y: auto;
}

.site__navigation {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  /* top: 0; */
  width: 100vw;
  visibility: hidden;
}

.menu__opend {
  visibility: visible !important;
}

.main__menu_ul,
.menu_right {
  opacity: 0;
  position: relative;
  transition: 0.5s;
  transition-delay: 0s;
  visibility: hidden;
  z-index: 100;
}

.menu_right {
  text-align: center;
}

.site__navigation.menu__opend .main__menu_ul,
.site__navigation.menu__opend .menu_right {
  opacity: 1;
  transition-delay: 0.6s;
  visibility: visible;
}

.site__navigation .main__menu_ul li {
  list-style: none;
  margin: 10px 0;
}

.site__navigation .main__menu_ul li a {
  color: black;
  display: block;
  font-size: 2.5rem;
  text-decoration: none;
}

.bg__menu {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: white;
  will-change: transform;
  transform: translateY(-100%);
  transition: 0.5s ease all;
}

.menu__opend .bg__menu {
  transform: translateY(0);
}

.menu__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden auto;
}

.the_menu {
  padding-top: 20vh;
  padding-bottom: 20vh;
  padding-left: 0;
}

@media (min-width: 992px) {
  .menu__container {
    margin-left: 33.3333%;
  }
  .the_menu {
    padding-top: 10vh;
    padding-bottom: 10vh;
  }
}

.the_menu .menu_item > a {
  color: black;
  line-height: 1;
  font-size: 2rem;
  display: inline-block;
  position: relative;
  transition: color 250ms cubic-bezier(0, 0, 0.58, 1) 0s;
  padding: 4px 0px;
  text-decoration: none;
  font-family: "Press Start 2P";
}

.the_menu .menu_item > a:hover {
  color: black;
}

@media (min-width: 768px) {
  .the_menu .menu_item > a {
    font-size: 4.8vw;
  }
}

.menu_footer {
  bottom: 0;
  font-family: "Press Start 2P";
  font-size: 14px;
  background: white;
}

.menu_footer a {
  color: black;
  margin-right: 10px;
  text-decoration: none;
}

.navbar-brand {
    padding-top: 10px !important;
    background-color: transparent;
    color: white !important;
    text-shadow: 2px 2px 3px #4AF626;

}

.nav-link {
color: white !important;
text-shadow: 2px 2px 3px #4AF626;
margin-left: 2vw;

}

.nav-link:hover {
    color: #4AF626 !important;
}